.content-parent-bg {
    background-color: #f4f4f4;
    min-height: inherit;
}

.blog-posts {
    margin-top: 2em;
    flex-wrap: wrap;
    margin-bottom: 0;
    display: flex;

    .blog-card-container {
        float: left;
        margin: 15px 0;
    }

    .blog-card {
        border-radius: 6px;
        background: #fff;
        height: 600px;;

        .post-img {
            display: block;
            border-bottom: 2px solid hsla(0,0%,58.8%,.1);
            img {
                width: 100%;
                overflow: hidden;
                border-radius: 6px 6px 0 0;
            }
            img:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }

        .post-content {
            padding: 1em 1em 0 1em;

            .post-text {
                h3 {
                    font-size: 26px;
                    margin-bottom: 0.25em;
                }
                p {
                    font-size: 14px;
                }
                h3:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            

        }
    }
}

.post-btn {
    position: absolute;
    bottom: 0;
    margin: 1em 0;
    .btn-link {
        border-radius: 2px;
        border: 2px solid #61c3d0;
        cursor: pointer;
        display: inline-block;
        padding: 0 1em;
        font-size: 14px;
        text-align: center;
        vertical-align: middle;
        outline: none;
        color: #61c3d0;
        height: 42px;
        line-height: 38px;
    }
    .btn-link:hover {
        cursor: pointer;
        color: #0c6b76;
        border-color: #0c6b76;
        text-decoration: none;
    }
}

.bg-white {
    background-color: #fff;
    height: 100%;
    width: 100%;
}

.blog-view-img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    border-bottom: 2px solid hsla(0,0%,58.8%,.1);
}
// .overview-img {
//     float: right;
//     margin-left: 0.5em;
//     height: 1000px;
// }

.post-header-text {
    text-align: center;
    margin-bottom: 1em;
    h1 {
        font-size: 48px;
        margin-top: 0.5rem;
        font-weight: 500;
    }
    .byline {
        display: flex;
        justify-content: center;
        color: #636466;
        p {
            font-size: 1em;
        }

        span {
            font-size: 1em;
            margin: 0 0.5em;
        }
    }
}

.post-article-text {
    font-size: 14px;
    .overview-img {
        float: right;
        height: 25rem;
    }
    .overview-img2 {
        float:left;
        height: 10rem;
    }
    ul, ol {
        margin-left: 3em;
    }
}

.post-footer {
    margin-top: 48px;
    padding-bottom: 200px;
}

.about-author {
    display: flex;
    border-top: 1px solid hsla(0,0%,58.8%,.4);
    border-bottom: 1px solid hsla(0,0%,58.8%,.4);
    padding: 1em;
    align-items: center;
    .about-author-img {
        padding-right: 1em;
        img {
            border-radius: 50%;
            display: block;
            overflow: hidden;
            width: 100px;
        }
    }
    .about-author-content {
        .author-byline {
            color: #61c3d0;
            font-weight: 600;
            margin-bottom: 0.5em;
        }
        .author-bio {
            font-size: 14px;
            margin-bottom: 0;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-posts {
        justify-content: flex-start;
        .blog-card {
            height: 560px;
            .post-content {
                .post-text {
                    h3 {
                        font-size: 24px;
                    }
                    p {
                        font-size: 44px;
                    }    
                }
            }
        }
    }

    .post-btn {
        .btn-link {
            height: 39.5px;
            font-size: 13.5px;
            line-height: 34.5px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .post-header-text {
        h1 {
            font-size: 42px;
        }

        .byline {
            p, span {
                font-size: 14px;
            }
        }
    }

    .post-article-text {
        font-size: 14px;
        .overview-img {
            float:none;
            height: 15rem;
        }
        .overview-img2 {
            float:none;
            height: 10rem;
        }
    }

    .blog-posts {
        .blog-card-container {
            padding-left: 0;
            padding-right: 0;
        }
        .blog-card {
            height: auto;

            .post-content {
                .post-text {
                    padding-bottom: 4em;
                    h3 {
                        font-size: 24px;
                    }
                    p {
                        font-size: 13px;
                    }    
                }
            }
        }
    }

    .post-btn {
        .btn-link {
            height: 39px;
            font-size: 13px;
            line-height: 34px;
        }
    }

    .about-author {
        .about-author-content {
            .author-byline {
                font-size: 14px;
            }
            .author-bio {
                font-size: 12px;
            }
        }
    }
}