.result-parent {
    background: #f5f5f5;
    margin-top: 2em;
    border-radius: 6px;

    h2 {
        font-size: 30px;
    }

    // ensures only the correct cards have hovers
    .dashboard-item {
        .card:hover {
            cursor: pointer;
        }
    }

    .card {
        padding: 1.5em 1em;
        position: relative;
        box-shadow: -1px 2px 5px 0 hsla(0,0%,49%,.2);
        border: 1px solid hsla(0,0%,58.8%,.1);
        border-radius: 6px;
        background: #fff;


        &.red {
            .result-icon {
                -webkit-filter: hue-rotate(150deg);
                filter: hue-rotate(150deg);
            }
            .result-value {
                color: #e74c3c;
            }        
        }

        &.blue {
            .result-icon {
                -webkit-filter: hue-rotate(340deg);
                filter: hue-rotate(340deg);
            }
            .result-value {
                color: #53cac4
            }        
        }

        &.gray {
            color: #888; // both title and risk are colored to take focus off unflagged results
            .result-icon {
              -webkit-filter: grayscale(90%);
              filter: grayscale(90%);
            }      
        }

        &.green {
            .result-icon {
                -webkit-filter: hue-rotate(300deg);
                filter: hue-rotate(300deg);
            }
            .result-value {
                color: #2ecc71
            }        
        }

        .result-icon {
            margin-bottom: .5rem;
            border: 2px dashed #62c3d0;
            border-radius: 50%;
            height: 120px;
            padding: .5rem;
        }
    }

    // .card:hover {
    //     cursor: pointer;
    // }

    ul {
        padding-left: 40px;
    }

    .title-break {
        margin-bottom: 1em;
        border-bottom: 1px solid #bbb;
        width: 100%;
        height: 1.32em;
        text-align: center;

        span {
            padding: 0 10px;
            font-size: 1.5em;
            font-size: 1.5em;
            background-color: #f5f5f5;
            color: #555
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .result-parent {
        h2 {
            font-size: 28px;
        }

        p, ul {
            font-size: 15px;
        }

        .title-break {
            height: 1.22em;
            span {
                font-size: 22.5px;
            }
        }
        .card {
            .result-name, .result-value {
                font-size: 15px;
            }
            .result-icon {
                height: 112px;
            }
        }
    }
}

@media (max-width: 767px) {
    .result-parent {
        h2 {
            font-size: 22px;
        }

        p, ul {
            font-size: 14px;
        }

        .title-break {
            height: 1em;
            span {
                font-size: 18px;
            }
        }

        .card {
            padding: 1.2em;

            .result-icon {
                height: 100px;
                margin-bottom: 0;
            }
            
            .result-name, .result-value {
                font-size: 14px;
            }
        }
    }
}


@media (max-width: 319px) {
    .result-parent {
        margin-top: 1em;
        h2 {
            font-size: 20px;
        }
        p, ul {
            font-size: 12px;
        }
        
        .title-break {
            height: 0.9em;
            span {
                font-size: 15px;
            }
        }

        .card {
            .result-icon {
                height: 80px;
            }
            
            .result-name, .result-value {
                font-size: 12px;
            }
        }

    }
}