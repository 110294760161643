.header-parent {
    position: sticky;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background: #fefefe;
    font-weight: 400;
    font-size: 20px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    box-shadow: -1px 2px 5px 0 hsla(0,0%,49%,.2);
    border-bottom: 1px solid hsla(0,0%,58.8%,.1);
}

.site-menu-bar {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo-container {
    width: calc(100% / 7.5 );
    display: flex;
    height: 100%;
    align-items: center;
    color: #0c6b76;

    a {
        text-decoration: none;
        color: inherit;
        
        span {
            display: flex;
            margin-left: 10px;
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
        }

        // NOTE: The height here influences the height of the entire navbar
        img {
            display: inline-block;
            vertical-align: top;
            height: 60px;
        }
    }
}


.site-menu {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch; 
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-end;
    margin-left: 2em;
    height: 100%;

    // TODO: fix bug where nav links don't take up entire height of navbar
    // This bug can be fixed when the header height is set to 60px, however that impacts the height of the logo img as well as the active style
    a {
        text-decoration: none;
        color: #0c6b76;
        transition: color 0.2s ease-out;
        padding: 8px 15px;
        font-size: 18px;
    }

    a:hover {
        cursor: pointer;
        color: #61c3d0;
        text-decoration: none;
    }

    .session-nav {
        display: flex;
        flex-direction: row;
        align-items: center;

        .reg-btn {
            border: 1px solid #006e79;
            border-radius: 6px;
            padding: 0 30px;
            margin-left: 15px;
            margin-right: 0;
        }
        .reg-btn:hover {
            background-color: #006e79;
            color: #fff;
        }

        button {
            margin: 15px 0 15px 15px;
            border-color: #006e79;
            border-width: 1px;
            border-style: solid;           
            border-radius: 6px;
            height: 32px;
            overflow: hidden;
            outline-width: 2px;
            padding: 0 16px;
            position: relative;
            text-align: center;
            text-decoration: none;
            transition-duration: 167ms;
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            transition-property: background-color,box-shadow,color,-webkit-box-shadow;
            vertical-align: middle;
            box-shadow: inset 0 0 0 1px rgba(255,255,255,0.7), inset 0 0 0 2px rgba(0,0,0,0), inset 0 0 0 1px rgba(0,0,0,0);
            background: transparent;
        }
    
        button:hover {
            background-color: #006e79;
            cursor: pointer;
    
            a {
                color: #fff;
            }
        }    
    }

    .bar-content {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .nav-link.active span {
        padding-bottom: 8px;
        border-bottom: 1.5px solid #0c6b76;
    }
}
// the following three styles handle styling the bootstrap dropdown menu
.dropdown-menu {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.dropdown-item {
    padding: 0.3rem 1.5rem;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #61c3d0;
}


.MuiMenu-list {
    .MuiMenuItem-root {
        min-width: 160px;
    }
}

.dropdown-toggle::after {
    display: none;
}

// for medium devices
@media (min-width: 768px) and (max-width: 991px) {
    .site-menu-mobile {
        width: 100%;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        li span i {
            color: #006e79;
        }

        button {
            color: #006e79;
            padding: 0;
        }
        button:focus {
            outline: transparent;
        }
    }
}

@media screen and (max-width: 767px) {

    // the following ensures the nav menu container does not overlap the logo on mobile
    .logo-container {
        width: 100%;
        margin-right: 0;

        a {
            img {
                height: 55px;
            }
        }
    }
    
    .site-menu {
        display: none;
    }

    .site-menu-mobile {
        width: 100%;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        li span i {
            color: #006e79;
        }

        button {
            color: #006e79;
            padding: 0;
        }
        button:focus {
            outline: transparent;
        }
    }

    .site-logo img {
        max-width: 141px;
    }  
    .site-logo {
        display: inline-block;
    }
}