.footer-parent {
  z-index: 99;
  width: 100%;
  bottom: 0;
  left: 0;
  display: block;
  background: #fefefe;
  font-weight: 400;
  font-size: 20px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  box-shadow: -1px 2px 5px 0 hsla(0,0%,49%,.2);
}

// sets colorful footer background
// allows for the pseudo-selector to be seen (via position: relative)
.footer-background {
  background: #07484f;
  position: relative;
}

.footer-top {
  padding-top: 0;
  padding-bottom: 20px;
}

.footer-logo-container {
	position: relative;
  z-index: 100;
  text-align: center;
}
.footer-logo-container a img {
  max-width: 150px;
}

.footer-social-icons {
  text-align: center;
}
.footer-social-icons a {
  font-size: 20px;
  margin: 0 11px;
  color: #fff;
}
.footer-social-icons a:hover {
  color: #61c3d0;
}

.footer-menu {
  text-align: center;
  margin: 20px 0;
}
.footer-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-menu ul li {
  display: inline-block;
}
.footer-menu ul li a {
  display: block;
  font-size: 16px;
  color: #fff;
  padding: 4px 19px;
  font-weight: 400;
}

.footer-sp {
  position: absolute;
  left: 0px;
  top: -120px;
  width: 100%;
  height: 222px;
  background: transparent;
  z-index: 99;
  overflow: hidden;
}
.footer-sp:after {
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: 0;
  content: "";
  border-left: 1200px solid transparent;
  border-bottom: 150px solid #07484f;
  border-right: 1200px solid transparent;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.footer-bottom {
    width:100%;
    text-align: center;
    margin-top: 20px;
    color: #fff;
    font-size: 14px;
    
    p {
        margin-bottom: 0;
    }
    hr {
        background: #fff;

    }
}


@media screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu ul li a {
    font-size: 14px;
    padding: 4px 14px;
  }
  .footer-logo-container a img {
    max-width: 135px;
  }
}


@media screen and (max-width: 767px) {
  .footer-sp {
    display: none;
  }
  .footer-sp:after {
    display: none;
  }

  .footer-top {
    padding-top: 20px;
    padding-bottom: 20px;
  }  

  .footer-menu ul li a {
    font-size: 16px;
    padding: 4px 8px;
  }
  .footer-social-icons {
    margin-top: 15px;
  }
  .footer-area {
    padding-bottom: 15px;
  }
  .footer-menu {
    margin-bottom: 0;
  }
  .footer-logo-container a img {
    max-width: 120px;
  }    
}