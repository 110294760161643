
/* the following styles influence the entire app */

/* the following selector removes default styles in the user agent stylesheet to prevent inherited style */

* {
  margin: 0;
  padding: 0;
}

/* this class impacts the entre app, including the header, footer, and content */
.app-parent {
  min-height: calc(100vh - 40px);
  margin: 0;
  padding: 0;
}

.hide {
  display: none;
}


/* 
content-wrapper
*/
.content-wrapper-bannerless {
  width: 100%;
  /* margin-top: 61px; */
  min-height: inherit;
}
.content-wrapper-bannered {
  min-height: inherit;
  width: 100%;
}


/* header-footer container */
/* NOTE: padding is handled in about.scss */
.hf-container {
  width: 100%;
  margin: 0 auto;
  /* max width subject to change, some comparison sites do 1240px */
  max-width: 1440px;
}