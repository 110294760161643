// general section syle rules:
// had issues with inherited bootstrap styles, which suddenly went away
// section tags define the top and bottom of the section, keep description margin/padding consistent
// actual unique content has no bottom margin and top margin of 2em
// consistency and aesthetics go hand in hand, as the human eye enjoys symmetrym while inconsistencies are instantly the focus

// this class defines the content-sections
.content-section {
    margin-top: 60px;
    margin-bottom: 65px;
}

.hero-content, .content-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;  
}

// handles adding the padding for all of the important sections
.hero-content, .content-container, .hf-container {
    padding-left: 20px;
    padding-right: 20px;
}

.top-hero {
    background-image: linear-gradient(#006e79, #63c3d0);
    color: #fff;
    height: 20em; //320px

    .hero-content {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .hero-text {
            position: relative;
            h1, p {
                font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
                letter-spacing: 0.01em;
            }

            h1 {
                font-size: 60px;
                text-align: center;
                margin-bottom: 16px;
                letter-spacing: 0.01em;
            }
            p {
                text-align: center;
                font-size: 30px;
                margin-bottom: 0;
            }
        }
    }
}

.hero-content::before {
    position: absolute;
    top: 0;
    left: 25%;
    background-size: 436px auto;
    bottom: 0;
    right: 0;
    background-position: 100% 100px;
	width: 50%;
	height: 100%;
    background-image: url('/assets/imgs/x-img.png');
	content: "";
    background-repeat: no-repeat;
    background-position: center center;
}

.section-header {
    h2, p {
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
        color: #212529;
    }
    
    h2 {
        font-size: 30px;
        text-align: center;
        position: relative;

        span {
            display: inline-block;
        }
        span::before, span::after {
            border-top: 2px solid #212529;
            display: block;
            height: 1px;
            content: " ";
            width: calc(40% - 5vw);
            position: absolute;
            left: 0;
            top: 0.6em;        
        }
        span::after {
            right: 0;  
            left: auto;          
        }
    }

    p {
        font-size: 24px;
        padding-top: 20px;
        text-align: center;
    }
}

.team-section {
    padding-top: 2em;
    display: block;
    h3, h5, p {
        color: #212529;
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
    ul {
        list-style: none;

        li {
            margin-right: 2.35765%;
            text-align: center;
            margin-bottom: 20px;
            padding: 15px;
            width: 23.23176%;
            display: inline-block;
            vertical-align: top;


            .avatar-container {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 0.5em;
                border: 12px solid transparent;

                img {
                    width: 200px;
                    height: 100%;
                    display: block;
                    border-radius: 100%;
                }
            }

            h3 {
                font-size: 22px;
                font-weight: 400;
            }

            h5 {
                font-size: 18px;
                font-weight: 300;
                padding-top: 5px;
            }

            p {
                padding-top: 1em;
                text-align: left;
                font-size: 14.5px;
            }
        }
    }
    ul::after {
        clear: both;
        content: "";
        display: table;    
    }
}

.leadership-section {
    padding-top: 2em;
    display: block;
    h3, h5, p {
        color: #212529;
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
    ul {
        list-style: none;

        li {
            margin-right: 2.35765%;
            text-align: center;
            margin-bottom: 20px;
            padding: 15px;
            width: 46%;
            display: inline-block;
            vertical-align: top;


            .avatar-container {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 0.5em;
                border: 12px solid transparent;

                img {
                    width: 200px;
                    height: 100%;
                    display: block;
                    border-radius: 100%;
                }
            }

            h3 {
                font-size: 22px;
                font-weight: 400;
            }

            h5 {
                font-size: 18px;
                font-weight: 300;
                padding-top: 5px;
            }

            p {
                padding-top: 1em;
                text-align: left;
                font-size: 14.5px;
            }
        }
    }
    ul::after {
        clear: both;
        content: "";
        display: table;    
    }
}


.team-section ul li:nth-child(4n+1){
    clear: left;
}

.team-section ul li:nth-child(4n){
    margin-right: 0;
}


.values-list {
    padding-top: 2em;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;

    li {
        display: block;
        padding: 1em;
        height: 100%;
        width: 25em;
        justify-content: center;

        .fa, .fas, .far {
            font-size: 4.5em;
            color: #63c3d0;
            padding: 0.5em;
        }

        .value-list-content {
            h3 {
                text-align: center;
                font-size: 22px;
                display: block;
                font-weight: 400;
                span {
                    width: 100%;
                    font-weight: 700;
                }
            }
            p {
                font-size: 19px;
                text-align: center;
                margin-bottom: 0;
            }
        }

        .another-container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 1em;

            .value-icon-container {
                border-radius: 50%;
                border: 2px solid #63c3d0;
                width: 7.5em;
                height: 7.5em;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}


// this style is essential for the tabs, as it ensures there is space between the footer and content

.content-container {
    .content-section:last-child {
        padding-bottom: 200px;
    }
}

.testimonials-parent {
    flex: 1;
    display: flex;
    flex-direction: column;

    .testimonial-content {
        flex-direction: row;
        display: flex;
        padding-top: 0.5em;
        border-top: 1px solid #fff;

        .testimonial-img-container {
            width: 50%;
            justify-content: center;
            align-items: center;
            
            img {
                width: 100%;
                object-fit: contain;
                border-radius: 6px;
            }
        }

        .testimonial-text {
            width: 50%;
            padding-left: 1em;

            h3 {
                font-size: 22px;
                text-align: center;
                color: #212529;
                margin-bottom: 0;
                padding-top: 10px;
                text-align: left;
            }
            h3:after {
                content: "";
                display: block;
                width: 8%;
                padding-top: 10px;
                margin: 0 0;
                border-bottom: 1px solid #212529;
            }

            .testimonial-title {
                margin-bottom: 0;
                margin-top: 0.5em;
                font-style: italic;
            }
            .speech-bubble {
                border-radius: 20px;
                border: 5px solid #8cdee9;
                padding: 1em;
                z-index: 2;
                background: #fff;
                p {
                    color: #212529;
                    font-size: 20px;
                    text-align: left;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                } 
            }
            .speech-bubble:after {
                border-right: 30px solid transparent;
                border-top: 30px solid #8cdee9;
                bottom: -67px;
                content: "";
                position: relative;
                left: 70px;
                z-index: 1;
            }
        }
    }

    .image-selection-menu {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        .li-inactive {
            text-align: center;
            padding-left: 1em;
            padding-right: 1em;
            padding-top: 0.5em;
            padding-bottom: 1em;
            min-width: 10em;

            h3 {
                font-size: 20px;
                font-weight: 300;
                margin-bottom: 0;
            }
        }

        li {
            border-top: 1px solid black;
            border-left: 1px solid black;
            border-right: 1px solid black;
            border-radius: 20px 20px 0 0;
            margin-right: 0.12em;
            margin-left: 0.12em;
            background: #fff;
            font-size: 16px;
        }

        li:first-child {
            margin-right: 0.12em;
            margin-left: 0;
        }

        li:last-child {
            margin-left: 0.12em;
            margin-right: 0;
        }


        .li-active {
            text-align: center;
            padding-top: 0.5em;
            padding-bottom: 1em;
            padding-left: 1em;
            padding-right: 1em;
            background-color: #8cdee9;
            color: #fff;
            min-width: 10em;

            h3 {
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 0;
            }
        }

        li:hover {
            cursor: pointer;
            color: #fff;
            background-color: #8cdee9;
        }
    }

}

.team-section ul li:nth-child(4n+1){
    clear: left;
}

.team-section ul li:nth-child(4n){
    margin-right: 0;
}

.content-section {
    .list-list, .content-text {
        font-size: 20px;
    }
    .list-list {
        margin-left: 20px;
    }
    .content-text {
        margin-top: 1em;
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    .section-header {
        p {
            font-size: 21px;
        }
        h2 {
            font-size: 28px;
        }
    }

    // this defines the pseudo-selector dividing lines for each section on MD devices. the styles for larger devices are found in about.scss
    .enterprises-parent, .athletes-parent, .content-parent, .content-parent-bg {
        .content-container .content-section .section-header {
            h2 span:before, h2 span:after {
                width: calc(40% - 8vw);
            }
            &.condensed {
                h2 span:before, h2 span:after {
                    width: calc(40% - 14vw);
                }
            }
        }
    }    

    .top-hero {
        height: 18em; //0.9x reduction
        .hero-content {
            .hero-text {
                h1 {
                    font-size: 54px;
                    margin-bottom: 14.4px;
                }
                p {
                    font-size: 27px;
                }
            }    
        }
    }

    .hero-content::before {
        background-size: 392.4px auto;
    }

    .team-section {
        ul {
            li {
                width: 33.33%;
                margin-right: 0;
                margin-bottom: 10px;
                h5 {
                    text-align: center;
                }

                .avatar-container {
                    img {
                        width: 180px;
                    }
                }
            }
        }
    }

    // the md device width has 3 rather than 4 team members in a row, so the n needs to be changed to 3n 
    .team-section ul li:nth-child(4n+1){
        clear: none;
    }    
    .team-section ul li:nth-child(4n){
        clear: none;
    }
    .team-section ul li:nth-child(3n+1){
        clear: left;
    }

    .testimonials-parent {
        .image-selection-menu {
            .li-inactive {
                min-width: 9em;
                h3 {
                    font-size: 18px;
                }
            }
            .li-active {
                min-width: 9em;
                h3 {
                    font-size: 18px;
                }
            }
        }
        .testimonial-content {
            .testimonial-text {
                h3 {
                    font-size: 20px;
                }
                h3:after {
                    content: "";
                    display: block;
                    width: 8%;
                    padding-top: 10px;
                    margin: 0 0;
                    border-bottom: 1px solid #212529;
                }
    
                .speech-bubble {
                    p {
                        font-size: 18px;
                        margin-bottom: 0.5em;
                    } 
                }
            }
        }
    }
    .content-section {
        .list-list, .content-text {
            font-size: 18px;
        }
    }
}


@media only screen and (max-width: 767px) {
    // this selector applies to every page, modifes the pseudo-selector that defines the dividing lines each header
    .enterprises-parent, .athletes-parent, .content-parent, .content-parent-bg {
        .content-container .content-section .section-header {
            h2 span:before, h2 span:after {
                width: calc(40% - 19vw);
            }

            &.condensed {
                h2 span:before, h2 span:after {
                    width: calc(40% - 32vw);
                }
            }    
        }
    }    

    // changed width all in one place for more flexibility
    .hero-content, .content-container, .footer-top, .header-bar-section {
        width: 100%;
    }
    
    .content-section {
        margin-top: 30px;
        margin-bottom: 0;
    }

    .top-hero {
        height: 14em;
        .hero-content {
            .hero-text {
                h1 {
                    text-align: center;
                    margin: 0;
                    font-size: 48px;
                    letter-spacing: 0.01em;
                }
                p {
                    font-size: 22px;
                }
            }
        }
    }
    
    .content-container {
        .content-section:last-child {
            padding-bottom: 48px;
        }
    }
            

    .section-header {
        p {
            font-size: 18px;
            padding-top: 10px;
            text-align: center;
        }
        h2 {
            font-size: 24px;
        }
    }
    
    .hero-content::before {
        width: 100%;
        left: 0;
        background-size: 305.2px auto;
    }

    .team-section {
        padding-top: 1em;
        h3, h5, p {
            color: #212529;
        }

        ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            li {
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;

                h3 {
                    font-size: 17px;
                }

                h5 {
                    font-size: 16px;
                }
                .avatar-container {
                    display: flex;
                    justify-content: center;
                    img {
                        width: 160px;
                    }
                }    
            }
        }
    }

    .values-list {
        padding-top: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
            width: auto;
            .value-list-content {
                
                h3 span {
                    font-size: 18px;
                }
                p {
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }

    .testimonials-parent {
        // overflow allows one to scroll and see more testimonials
        // overflow will only be visible on older than or equal to iPhone 5
        .image-selection-menu {
            overflow: scroll;
            scroll-behavior: smooth;
            -webkit-overflow-scrolling: touch;
            .li-inactive {
                min-width: 5em;
                padding-bottom: 0.5em;
                padding-top: 0.25em;

                h3 {
                    font-size: 14px;
                }
            }
            
            .li-active {
                min-width: 5em;
                padding-bottom: 0.5em;
                padding-top: 0.25em;
                h3 {
                    font-size: 14px;
                }
    
            }
        }

        .testimonial-content {
            flex-direction: column;
            padding-top: 0.5em;
            
            .testimonial-img-container {
                width: 100%;
            }

            .testimonial-text {
                width: 100%;
                padding-left: 0;
                padding-top: 0.5em;

                h3 {
                    font-size: 18px;
                }
                h3:after {
                    content: "";
                    display: block;
                    width: 8%;
                    padding-top: 10px;
                    margin: 0 0;
                    border-bottom: 1px solid #212529;
                }    
                .speech-bubble {
                    p {
                        font-size: 17px;
                        margin-bottom: 0.5em;
                    } 
                }
                .speech-bubble::after {
                    display: none;
                }
            }
        }
    }

    .content-section {
        .list-list, .content-text {
            font-size: 17px;
        }
    }
}


// specific styles for iPhone 5 and older devices, as without this styling certain elements do not fit properly on the page
@media only screen and (max-width: 320px) {
    .enterprises-parent, .athletes-parent, .content-parent, .content-parent-bg {
        .content-container .content-section .section-header {
            h2 span:before, h2 span:after {
                width: calc(40% - 23vw);
            }
        }
    }

    .section-header {
        p {
            font-size: 16px;
        }
        h2 {
            font-size: 22px;
        }
    }

    .top-hero {
        .hero-content {
            .hero-text {
                h1 {
                    font-size: 42px;
                }
                p {
                    font-size: 20px;
                }
            }
        }
    }

}