
.section-body {
    padding-top: 2em;
}

.media-list {
    display: block;
    li {
        padding: 16px 0 16px 0;
        display: flex;
        flex-direction: column;
        a {
            text-decoration: none;
            color: #61c3d0;
        }
        a:hover {
            color: #0c6b76;
        }
        p {
            font-size: 18px;
            margin-bottom: 0;
        }

        iframe {
            height: 160px;
            width: 100%;
            border-radius: 6px;
            margin-top: 1em;
        }
    }
}


@media screen and (max-width: 767px) {
    .section-body {
        padding-top: 1em;
    }

    .media-list {
        li {
            h5 {
                font-size: 16.6px;
            }
            p {
                font-size: 15px;
            }
        }
    }
}
