
.not-found-content {
    padding: 100px 0;
    img {
        height: 370px;
        float: right;
        max-width: 100%;
        object-fit: contain;
    }
    h1 {
        font-size: 3em;
    }
    p {
        font-size: 1.25em;
        a {
            color: #62c3d0;
        }
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    .not-found-content {
        img {
            height: 308px;
        }
        h1 {
            font-size: 2.5em;
        }
        p {
            font-size: 1em;
        }
    }    
}


@media (max-width: 767px) {
    .not-found-content {
        padding: 30px 0;

        img {
            float: none;
            height: 220px;
        }

        h1, p {
            text-align: center;
        }

        h1 {
            font-size: 1.75em;
        }

        p {
            font-size: 0.88em;
        }
    }    
}
