/*
place reusable element styles here
/*


/*
react-tooltip styles
*/
.__react_component_tooltip.type-dark.tooltip-axgen {
    background-color: #61c3d0;
    max-width: 300px;
    opacity: 0.95;
  
    &.place-top:after {
      border-top-color: #61c3d0;
    }
    &.place-bottom:after {
      border-bottom-color: #61c3d0;
    }
    &.place-right:after {
      border-right-color: #61c3d0;
    }
    &.place-left:after {
      border-left-color: #61c3d0;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .__react_component_tooltip.type-dark.tooltip-axgen {
        max-width: 260px;
    }
}

@media screen and (max-width: 767px) {
    .__react_component_tooltip.type-dark.tooltip-axgen {
        max-width: 260px;
    }
}