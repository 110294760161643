/*
    SaSS compilation is handled by webpack via a top-level `npm run build`
    or `npm run watch` command.

    To compile this SaSS into CSS without using webpack, do the following:
        cd /app/static/css
        sass --watch sass/main.scss:main.min.css --style compressed
*/

/* VENDORS - Default fall-backs and external files.
========================================================================== */


/* UTILS - Tool, setting, and configuration files.
========================================================================== */
@import './stylesheets/fontawesome.scss';


/* BASE - Boilerplate files.
========================================================================== */

/* LAYOUTS - Structure, section, and layout files.
========================================================================== */


/* MODULES - Re-usable site elements.
========================================================================== */
@import './stylesheets/nav.scss';
@import './stylesheets/carousel.scss';
@import './stylesheets/footer.scss';
@import './stylesheets/elements.scss';
@import './stylesheets/not-found-page.scss';


/* PAGES - Page specific files.
========================================================================== */
@import './stylesheets/about.scss';
@import './stylesheets/enterprises.scss';
@import './stylesheets/home.scss';
@import './stylesheets/athletes.scss';
@import './stylesheets/media.scss';
@import './stylesheets/blog.scss';
@import './stylesheets/what-you-get.scss';
@import './stylesheets/science.scss';
@import './stylesheets/form.scss';
