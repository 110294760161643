// to do: get color right for the central icons, may want to opt for a solid color

.works-container {
    width: 100%;
    background-image: url('/assets/imgs/the-new-line.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    .item-header {
        z-index: 2;
        span {
            width: 10em;
            height: 10em;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            flex-basis: initial;
            position: relative;
            background: #fff;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;

            //circular border around images
            border: 3px solid #63c3d0;
            img {
                filter: brightness(0);
                height: 90px;
            }
        }
    }
}

// defines the min height of the rows allowing for proper spacing and as much content as we want per section
.left-works, .right-works {
    min-height: 15em;
    padding: 1em;

    .item-p {
        height: 100%;
        width: 100%;
        h3, p {
            color: #212529;
        }
        h2 {
            text-align: center;
            color: #212529;
        }
        p {
            margin-bottom: 0;
        }
    }


    .step-title {
        span {
            color: #212529;
            border-radius: 50%;
            height: 2em;
            width: 2em;
            background: #fff;
            border: 3px solid #63c3d0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 32px;
            font-weight: 700;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            z-index: 2;
        }
    }
}

// the rows are left-works and right-works, both use css grids to layout their content
.left-works {
    display: grid;
    grid-template-columns: 1fr 0.25fr 1fr;
    grid-template-areas: "a b c";
    height: auto;
    .item-p {
        grid-area: a;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 1em;
    }
    .item-header {
        grid-area: b;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .step-title {
        grid-area: c;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        // the z index ensures line does not overlap, while the right and margin position it        
        
        hr {
            border-top: 3px dashed #63c3d0;
            position: relative;
            z-index: 1;
            width: 50%;
        }
    }
}


.right-works {
    display: grid;
    grid-template-columns: 1fr 0.25fr 1fr;
    grid-template-areas: "c b a";
    height: auto;    
    .item-p {
        grid-area: a;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 1em;
    }
    .item-header {
        grid-area: b;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .step-title {
        grid-area: c;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        hr {
            border-top: 3px dashed #63c3d0;
            position: relative;
            z-index: 1;
            width: 50%;
        }
    }
}

// the following styles the faq area
.faq-area {
    margin-top: 28px;
    background-size: 100% 100%;
    background-position: center center;
    border-radius: 6px;
}

.faq-main-content-all .card-header {
    padding: 0;
    background: transparent;
    border: none;
    padding: 20px;
}
.faq-main-content-all .card-header button {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 20px;
    color: #212529;
    background: transparent;
    border: none;
    position: relative;
    white-space: normal;
    outline: none;
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-right: 0.75rem;
    padding-left: calc(0.75rem + 50px);
    line-height: 1.5;
    border-radius: .25rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.faq-main-content-all .card-header button div {
    max-width: 90%;
}

.faq-main-content-all .card-header:hover {
    cursor: pointer;

    button div {
        text-decoration: underline;
    }
}

.faq-main-content-all .card {
    background: transparent;
    border: none;
}
.faq-main-content-all .card-header h5 {
    border: none;
}
.faq-main-content-all .card-header button span {
    position: absolute;
    left: 0;
    color: #62c3d0;
    font-size: 22px;
}
.card-body {
    padding-left: 50px;
}

.faq-main-content-all .card-header button i {
    width: 32px;
    height: 32px;
    border: 1px solid #e0e0e0;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    color: #a1a1a1;
    position: absolute;
    right: 18px;
    top: 5px;
    background: #f5f5f5;
}

.faq-main-content-all .card-header button i:hover {
    color: #212529;
    border: 1px solid #212529;
}

.faq-main-content-all .card-header button i.fas.fa-minus {
    display: block;
    color: #5ed8eb;
    border-color: #5ed8eb;
    background: transparent;
}
.faq-main-content-all .card-header button i.fas.fa-plus {
    display: block;
}

.card-current {
    background: #fff;
    -webkit-box-shadow: 0 0 30px #ddd;
    box-shadow: 0 0 30px #ddd;
    border-radius: 10px !important;
    border: none;
    margin-bottom: 28px;
}

.card.current .card-body {
    margin-left: 50px;
    border-top: 1px solid #5ed8eb;
    padding-left: 0;
    margin-right: 15px;
    padding-right: 0;
    margin-top: 15px;
}
.card-body {
    font-size: 20px;
    color: #212529;
    line-height: 33px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
}
.faq-main-content-all .card-header button br {
    display: none;
}

.collapsed-faq {
    margin-left: 50px;
    border-top: 1px solid #5ed8eb;
    padding-left: 0;
    margin-right: 15px;
    padding-right: 0;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem; 
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    .left-works, .right-works {
        .step-title {
            span {
                font-size: 30px;
            }
        }
    }
}


// small-sized devices
@media only screen and (max-width: 767px) {
    .faq-main-content-all .card-header button div {
        max-width: 80%;
    }

    .faq-main-content-all .card-header button {
        font-size: 18px;
    }


    .faq-main-content-all .card-header button span {
        font-size: 20px;
    }

    .faq-main-content-all .card-header button i {
        height: 30px;
        width: 30px;
        line-height: 29px;
        right: 0;
    }

    .card-body {
        padding-left: 40px;
        font-size: 18px;
    }

    .works-container {
        margin-top: 1em;
        background-image: none;
    }

    .right-works {
        grid-template-areas: "c c c" "b b b" "a a a";

        .item-p {
            padding-top: 0.5em;
            margin-right: 0;
            margin-left: 0;
            p {
                text-align: center;
            }
        }
        .step-title {
            justify-content: center;
            margin-bottom: 0.5em;
            hr {
                display: none;
            }
            span {
                font-size: 28px;
            }
        }
    }

    .left-works {
        grid-template-areas: "c c c" "b b b" "a a a";
        .item-p {
            padding-top: 0.5em;
            margin-right: 0;
            margin-left: 0;
            p {
                text-align: center;
            }
        }

        .step-title {
            justify-content: center;
            margin-bottom: 0.5em;
            hr {
                display: none;
            }
            span {
                font-size: 28px;
            }
        }
    }
}