.contact-section {
    h3, h5 {
        color: #212529;
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }

    width: 100%;

    .inquiries-form {
        margin-top: 2em;
        form {
            padding: 1em 2em;
            width: 45em;
            border-radius: 6px;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
            .name-text {
                padding-right: 0.5em;
                width: 50%;
            }
            .name-text-2 {
                width: 50%;
                padding-left: 0.5em;
            }

            .ent-btn {
                margin-top: 20px;
                background: #63c3d0;
            }
            // this style handles button disabling
            .MuiButton-contained.Mui-disabled {
                background: rgba(0, 0, 0, 0.12);
            }

            .name-text, .name-text-2, .inquiries-input {
                color: #0c6b76
            }
            // the following handles outlining focus inputs correctly
            .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: #63c3d0;
            }
        }
    }
}

// this styling handles any buttons placed within section elements in the site's subpages
.section-button {
    margin-top: 2em;
    width: 100%;
    text-align: center;
    p {
        font-size: 24px;
    }
    
    button, a {
        width: 170px;
        padding: 6px 20px 6px 20px;
        background: #63c3d0;
        border: none;
        color: #fff;
        border-radius: 6px;
        font-size: 24px;
    }

    button:hover, a:hover {
        cursor: pointer;
        background: #006d77;
    }
}

.offerings-parent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 2em;
}

.offer-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    li {
        flex: 1 0 50%;
        padding: 1em;
        display: inherit;

        .fa, .fas, .far {
            font-size: 4em;
            color: #63c3d0;
        }

        .offer-list-content {
            display: block;
            margin-left: 1.5em;

            h3 {
                font-size: 22px;
            }
            p {
                font-size: 19px;
                margin-bottom: 0;
            }
        }
    }
    .icon-container {
        min-width: 7em;
        display: inherit;
        align-items: center;
        justify-content: center;
    }
}

.our-partners {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
        flex: 1 0 33%;
        display: inherit;
        padding: 1em;
        flex-direction: column;
        align-items: center;
        img {
            height: 70px;
            object-fit: contain;
            width: 100%;
        }
        img:hover {
            cursor: pointer;
        }
        a {
            font-size: 16px;
            color: #212529;
            text-align: center;
            margin-top: 0.5em;
        }
        a:hover {
            color: #bbb;
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-button {
        p, button, a {
            font-size: 21px;
        }
    }

    .offerings-parent {
        .offer-list {
            li {
                flex-direction: column;
                .offer-list-content {
                    margin-left: 0;
                    h3 {
                        font-size: 20px;
                        text-align: center;
                    }
                    p {
                        font-size: 17px;
                        text-align: center;
                    }
                }
            }
            .icon-container {
                margin-bottom: 0.8em;
                text-align: center;
                min-width: 100%;
            }
        }
    }

    .our-partners li {
        flex: 1 0 50%;
    }
}

@media only screen and (max-width: 767px) {
    .section-button {
        margin-top: 1em;
        p, button, a {
            font-size: 18px;
        }
    }

    .offerings-parent {
        padding-top: 1em;
        .offer-list {
            li {
                flex: 1 0 100%;
                flex-direction: column;
                .offer-list-content {
                    margin-left: 0;
                    h3 {
                        font-size: 18px;
                        text-align: center;
                    }
                    p {
                        font-size: 15.3px;
                        text-align: center;
                    }
                }
            }
            .icon-container {
                margin-bottom: 0.8em;
                text-align: center;
                min-width: 100%;
            }
        }    
    }

    .our-partners {
        padding-top: 1em;
        li {
            flex: 1 0 100%;
            padding: 0.5em;
            img {
                object-fit: contain;
                width: 100%;
            }
            a {
                font-size: 14px;
            }
        }
    }
}

