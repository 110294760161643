// 
// *{

//     border: 1px solid red;
// }
.form-box{
    box-sizing: border-box;
    // margin: 0;
    // padding: 0;
    min-width: 100%;
    height: 18%;
    height: fit-content(20em);
    // border: solid black 5vw;
    /* border: 1px solid red; */
    margin: 0vw;
    padding: 0 ;
    position:relative; 
    /* position:relative for .prevBtn and .nextBtn position:absolute; */
}

.form-box {
    label {
        color: white;
        display: block;
        font-size: 1.4rem;
        margin: 0 0 2vh 0;
    }
}

.form-box input, textarea{
    display: block;
    width: 50%;
    padding: .5rem .8rem .5rem .8rem;
    margin: .9vw 0 ;
    border:0;
    border-radius: 5px;
    font-size: 20px;
}

.form-box textarea{
    height: 1vh;
}

.prevBtn, .nextBtn, .submitBtn {
    display: inline-block;
    width: 30%;
    height: 2rem;
    color: black;
    border: 0;
    bottom: 0;
    cursor: pointer;
    font-size: 20px;
    position:relative;

}

.submitBtn{
    margin: 1rem 0 1rem 0;
    border-radius: 20px 0 0 20px; 
    width: 120px;
    height: 40px;
    padding: 2px;
}

#submitBtn{
    width: 120px;
    height: 40px;
    padding: 2px;
}

.submitBtn:hover{
    background-color: rgb(245, 245, 78);
    transition: 1s background ease;
}

.prevBtn{
    margin: 1rem 0 1rem 0;
    border-radius: 20px 0 0 20px; 
}

.nextBtn{
    margin: 1rem 0 1rem 8rem;
    margin-left: 52%;   
    border-radius: 0 20px 20px 0; 
}

.nextBtn:hover, .prevBtn:hover{
    background-color: rgb(245, 245, 78);
    transition: 1s background ease;
}
.nextBtn{    
    margin-left: 52%;
}
.prevBtn, .nextBtn{    
    margin: 1rem 0 1rem 0; 
    display: inline-block;   
}


//.form {
//     input.form-control, select.form-control {
//       margin-bottom: 1.5em;
//     }
//   }
  
//   .checkbox-lg {
//     -ms-transform: scale(1.2);
//     -moz-transform: scale(1.2);
//     -webkit-transform: scale(1.2);
//     -o-transform: scale(1.2);
//     transform: scale(1.2);
//   }
  
//   .flex-form {
//     -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
//     box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
//     border: 1px solid rgba(0, 0, 0, 0.2);
//     border-radius: 4px;
//     width: 300px;
//     padding: 0em 2em 2em 2em;
//     background: #fafafa;
  
//     &.auth-form {
//       padding: 2em;
//     }
  
//     input.form-control {
//       margin-bottom: 1.5em;
//     }
//   }
  
//   .file-upload {
//     .card-container {
//       border: 2px dashed rgba($color-gray-dark, 0.3);
//       border-radius: 16px;
//       padding: 4em 1em;
//       text-align: center;
//       color: rgba($color-gray-dark, 0.5);
//       cursor: pointer;
  
//       &.active {
//         border-color: $color-primary;
//         color: $color-primary;
//       }
//       &.failed {
//         border-color: $color-red;
//         color: $color-red;
//       }
//     }
//   }
  
//   .download-instructions {
//     li {
//       padding: 0.75em 0;
//     }
//     .break-word {
//       word-break: break-word;
//     }
//   }
  
//   .order-option {
//     cursor: pointer;
  
//     .card-body {
//       div {
//         font-size: 1.5em;
//       }
//     }
  
//     &.active {
//       border: 1px solid $color-primary;
//       box-shadow: rgba($color-primary, 0.5) -1px 2px 5px 0px;
//       background-color: rgba($color-primary, 0.15);
//     }
//   }
  
//   // handles disabled order option for users without results that view the consultations page
//   .order-option-disabled {
//     .card-body {
//       div {
//         font-size: 1.5em;
//       }
//     }
//   }
  
//   // bulk order form styles
  
//   .bulk-option {
//     i {
//       font-size: 5em;
//       color: #63c3d0;
//     }
//   };
  
//   .email-list-field {
//     min-height: 2em;
//     border: 1px solid rgba($color-gray-dark, 0.3);
//     border-radius: 6px;
//     padding: 1em;
  
//     li {
//       width: 100%;
//       display: flex;
//       flex-direction: row;
//       list-style: none;
//       align-items: center;
//       padding: 0.25em;
//       border-radius: 6px;
  
//       p {
//         width: 80%;
//         overflow: hidden;
//         margin-bottom: 0;
//       }
//       .icon-container {
//         width: 20%;        
//         text-align: center;
//         i {
//           font-size: 1.2em;
//         }
//       }
//     }
//     li:hover {
//       cursor: pointer;
//       background-color: rgba(0, 0, 0, 0.04);
//     }
//     li:last-child {
//       margin-bottom: 0;
//     }
//   };
  
  
  
//   @media screen and (max-width: 767px) {
//     .flex-form {
//       margin-bottom: 10%;
  
//       &.auth-form {
//         padding: 2em 2em 0em 2em;
//       }
//     }
  
//   //the following is for the buy form
//     .buy-header {
//       h2 {
//         text-align: center;
//       }
//     }
  
//     .order-option, .order-option-disabled {
//       padding: 1em;
  
//       .card-body {
//         padding: 1em 0;
//         font-size: 1em;
//       }
//     }
  
//     .mobile-title {
//       text-align: center;  
//     }
  
//     .bulk-option {
//       i {
//         font-size: 3.5em;
//       }
  
//       h3 {
//         font-size: 0.5em;
//       }
//     };
//   }
  
//   @media screen and (max-width: 319px) {
//     // adjusts order options so the text does not bleed onto multiple lines in xs devices
//     .order-option, .order-option-disabled {
//       .card-body {
//         div {
//           font-size: 18px;
//         }
//       }
//     }
//   }
