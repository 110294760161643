

.research-section {
    display: grid;
    margin-right: 0;
    margin-left: 0;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    flex-wrap: wrap;
    margin-top: 2em;
}

.card-container {
    display: flex;
    min-height: 100%;
    height: 100%;
    flex-direction: column;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: rgba(125, 125, 125, 0.2) -1px 2px 5px 0px;
  }

.card-img-wrapper {
    height: 280px;
    margin-bottom: 27px;

    img {
        width: 100%;
        height: 100%;
        max-width: none;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

.card-content {
    display: flex;
    margin-right: 20px;
    margin-left: 20px;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    color: #212529;
}

// card body text
.card-text {
    a {
        display: block;
        margin-top: 15px;
        margin-bottom: 15px;
        color: #212529;
        font-size: 20px;
        line-height: 24px;
    }
    a:hover {
        text-decoration: underline;
    }
    p {
        color: #212529;
        font-size: 14px;
    }
}

// card footer
.card-fter {
    hr {
        height: 1px;
        margin-top: 10px;
        margin-bottom: 15px;
        background-color: #979797;
    }
}

.card-footer-link {
    margin-bottom: 0;
    font-size: 14px;
    a {
        color: #61c4d1;
    }
}
.card-footer-link:hover {
    cursor: pointer;
    text-decoration: underline;
}


.content-parent-bg {
    .content-section:last-child {
        margin-bottom: 0;
    }
}


@media screen and (min-width: 768px) and (max-width: 991px) {
    .card-text {
        a {
            font-size: 18px;
        }
    }
    .card-footer-link, .card-text p {
        font-size: 13px;
    }
}

@media screen and (max-width: 767px) {
    .research-section {
        grid-template-columns: 1fr;
    }
    .card-img-wrapper {
        height: 230px;
    }
    .card-text {
        a {
            font-size: 17px;
        }
    }
    .card-footer-link, .card-text p {
        font-size: 12px;
    }
}