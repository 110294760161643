// NOTE: this was all taken from the stylesheets from the original html/css/jquery website


/*
General section styles
*/
// textbox's are test code for splitting home screen 
.text-box-left {
  float: left;
  width: 50%;
  padding-right: 20px;
  box-sizing: border-box;
}
.text-box-right {
  float: right;
  width: 50%;
  padding-left: 20px;
  box-sizing: border-box;
}
.text-box-container:after {
  content: "";
  display: table;
  clear: both;
}
.homepage-section {
  padding-bottom: 65px;
  padding-top: 80px;
  position: relative;
  background: #ffffff;
  
  &.color {
    background-color: #07484f;

    .section-title {
      h1 span {
        color: #ffffff;
      }
      h1:after {
        background: #ffffff;
      }
    }

    .features-content {
      p {
        color: #ffffff;
      }
    }
  }
}
.homepage-section1 {
  padding-bottom: 20px;
  padding-top: 80px;
  position: relative;
  background: #ffffff;
  
  &.color {
    background-color: #07484f;

    .section-title {
      h1 span {
        color: #ffffff;
      }
      h1:after {
        background: #ffffff;
      }
    }

    .features-content {
      p {
        color: #ffffff;
      }
    }
  }
}

.features-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
};

.section-title {
  text-align: center;
  h1 {
    font-size: 48px;
    font-weight: 400;
    position: relative;
    span {
      color: #212529;
      font-weight: 500;
      align-content: center;
    }
  }
  h1:after {
    position: absolute;
    left: 50%;
    bottom: -12px;
    width: 100px;
    background: #212529;
    height: 2px;
    content: "";
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);  
  }
  h2 {
    font-size: 24px;
    color: #006e79;
    margin-top: 25px;
    font-weight: 400;  
  }
}

.features-content {
  .features-text {
    margin-bottom: 2em;
  }

  h2, a {
    color: #61c4d1;
  }

  img {
    object-fit: contain;
    width: 100%;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
  }
  ul {
    padding-left: 40px;
  }

  a {
    // font-weight: 600;
  }
  a:hover {
    text-decoration: underline;
  }

  .feat-img {
    margin-bottom: 40px;
  }
}

.color {
  .features-content {
    h2, a {
      color: #8cdee9;
    }
  }
}

//code for fitting video players on different devices

@media (min-width: 0px) and (max-width: 600px) {
  iframe {
     width: 100%;
     height: 100%;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  iframe {
     width: 100%;
     height: 100%;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  iframe {
     width: 800px;
     height: 500px;
  }
}

@media (min-width: 1000px) {
  iframe {
     width: 1000px;
     height: 630px;
  }
}

// handles first page section
// test code for homescreen 134-205

//PC home heading
  h1 {
    font-size: 70px;
    color: #ffffff;
    text-align: center;
    margin-top: 20px;
    z-index: 4;
  }
//phone home heading
    @media (max-width: 600px) {
      h1 {
        font-size: 40px;
        text-align: center;
        margin-top: 0px;
      }
    }
//tablet home heading (ignore)
    // @media (min-width: 992px) and (max-width: 1200px) {
    //   h1 {
    //     font-size: 60px;
    //     text-align: center;
    //     margin-top: 0px;
    //   }
    // }

//hyperlink color & styling for "AXGEN SPORTS"
.sports-link a {
  color: #9bd2d8;
  font-weight: bold;
  text-decoration: underline;
  font-style: italic;
}

//hyperlink color & styling for "AXGEN HEALTH"
.health-link a {
  color: #5bc89e;
  font-weight: bold;
  text-decoration: underline;
  font-style: italic;
}

.home-area {
  margin-left: auto;
  margin-right: auto;
  max-width: 2000px;
  width: 100%;
  height: 56em;
  z-index: 1;
  .home-box {
    z-index: 3;
    color: #ffffff;
    margin-right: auto;
    margin-left: auto;
    margin-top: 6em;
    margin-bottom: auto;
    width: 66%;
    
    h1 {
      font-size: 70px;
      text-align: center;
      margin-top: -150px;
    }
    p {
      font-size: 28px;
    }
//phone home text
    @media (max-width: 1000px) {
      p {
        font-size: 20px;
        margin-top: -90px
      }
    }
//tablet home text
    @media (max-width: 1000px) {
      p {
        font-size: 20px;
        margin-top: -70px
      }
    }
  }
  .home-img {
    background-image: url("/assets/imgs/horizontal-home.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-position: center;
    z-index: 1;
  }
  .home-img-filter {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    position: absolute;
    height: 59.8em;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .home-img-filter2 {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    position: absolute;
    height: 61em;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
.hero-area {
  margin-left: auto;
  margin-right: auto;
  max-width: 2000px;
  width: 100%;
  height: 56em;
  z-index: 1;

  .hero-img {
    background-image: url("/assets/imgs/man-img.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-position: center;
    z-index: 1;
  }
  .health-img {
    background-image: url("/assets/imgs/healthcare1.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-position: center;
    z-index: 1;
  }
  .home-img {
    background-image: url("/assets/imgs/horizontal-home.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-position: center;
    z-index: 1;
  }
  .osteo-img {
    background-image: url("/assets/imgs/osteostrong.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-position: center;
    z-index: 1;
  }
  .img-filter {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    position: absolute;
    height: 61em;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .img-filter2 {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    position: absolute;
    height: 61em;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .hero-box {
    z-index: 3;
    color: #ffffff;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 44%;
  
    h1 {
      font-size: 60px;
    }
    p {
      font-size: 24px;
    }
  }

  .osteo-box {
    z-index: 3;
    // color: #33F5FF;
    // color: #ff5733; //red
    color: #ffffff;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 90%;
  
    h1 {
      font-size: 60px;
    }
    p {
      font-size: 24px;
    }
  }
}

.tests-section {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .tests-text {      
    max-width: 38.6667%;
    flex-basis: 38.6667%;
    padding-right: 2em;
    display: block;
  }
  .img-container {
    max-width: 61.3333%;
    flex-basis: 61.3333%;
    padding-left: 2em;
  }
}

#our-partners-area {
  z-index: 3;
}

#our-partners-area::after {
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-image: url('/assets/imgs/header-wev-img-wt.png');
  content: "";
  height: 198px;
  background-size: 100% 100%;
  background-position: center center;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);  
}

// NOTE: text size on homepage that is not header should be 20px
// NOTE: this font size ensures consistency between the different sections
// NOTE: This size should match the sizes of the text elements in the testimonials section in about.scss
.process-step-main h4, .features-content p  {
  font-size: 20px;
}

/* sale bar section start */
.sale-bar-container {
  width: 100%;
  height: 30px;
  position: relative;
  background: #026d78;
  border-radius: 6px;
  text-align: center;  
  border: 1px solid #cfced4;
  font-size: 18px;
  padding: 1em 0;
  animation: bounceIntro 1500ms linear forwards;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sale-bar-container:hover {
  cursor: pointer;
}

.sale-bar-container a {
  width: 100%;
  text-decoration: none;
  text-align: center;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
}

.sale-bar-container a:hover {
  cursor: pointer;
  color: #cfced4;
}

@keyframes bounceIntro{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.95);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}
/* sale bar section end */


/* tos bar css section start */
.tos-bar-container {
  width: 100%;
  position: relative;
  background: #026d78;
  border-radius: 6px;
  text-align: center;  
  border: 1px solid #cfced4;
  font-size: 16px;
  padding: 0.5em 0;
  display: flex;
  list-style: none;
  flex-direction: column;
  margin-bottom: 0;

  li {
    color: #fff;
    height: 30px;
    display: inherit;
    align-items: center;
    width: 100%;
    justify-content: center;
    max-width: 1440px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  li:hover {
    text-decoration: underline;
  }
}

.tos-bar-container:hover {
  cursor: pointer;
}
/* tos bar css section end */


/* trusted by css start */
.trusted-by {
  padding-left: 0 !important;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 1 0 20%;
    display: inherit;
    padding: 1em;
    flex-direction: column;
    align-items: center;
    img {
      height: 85px;
    }
  }
}
/* trusted by css end */

/* trusted by css start */
.trusted-by {
  padding-left: 0 !important;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 1 0 20%;
    display: inherit;
    padding: 1em;
    flex-direction: column;
    align-items: center;
    img {
      height: 85px;
    }
  }
}
/* trusted by css end */

/* testimonials area start */

// attached pseudo selector to the tests section rather than the footer section, to avoid the weird line that appears on ipad devices
// TODO: consider removing
#testimonials-area:after {
  transform: rotate(180deg);
  z-index: 10;
  position: absolute;
	width: 100%;
	height: 100px;
	background-image: url('/assets/imgs/new-wave.png');
  content: none;
  background-size: 100% 100%;
  background-position: center center;
  bottom: -99px;
}

.test:hover {
  transition: transform .2s;
  transform: scale(1.05);
}

#testimonials-area .test h4 {
  margin-bottom: 0;
  padding-top: 0.5em;
}


/*
what we test for
*/
.tests-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 1 0 25%;
    display: inherit;
    align-items: center;
    flex-direction: column;
    padding: 1em;
    img {
      height: 120px;
      border-radius: 100%;
      border: 1px solid #fff;
      padding: 0.5em;
      background: #fff;
    }
    p {
      text-align: center;
      margin-bottom: 0;
      margin-top: 0.5em;
      color: #fff;
    }
  }
}

/* What we test for area css end */

/* Process area css start   */
#process-section {
	position: relative;
  z-index: 9;
}

.process-step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.signle-p-step {
	max-width: 19.33333%;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
}
.process-step-main {
  text-align: center;
}
.process-step-main h4 {
  font-weight: 400;
  color: #212529;
  margin-top: 7px;
}

.process-img {
	width: 142px;
	height: 142px;
	background: #61c2cf;
	text-align: center;
	border-radius: 50%;
	line-height: 142px;
	position: relative;
	margin: 0 auto;
}
.process-img span {
	position: absolute;
	left: 1px;
	top: 12px;
	width: 30px;
	height: 30px;
	background: #036f79;
	line-height: 30px;
	color: #fff;
	border-radius: 50%;
	font-size: 15px;
}
.process-step .signle-p-step:nth-child(1) {
  margin-top: 31%;
}
.process-step .signle-p-step:nth-child(5) {
  margin-top: 31%;
}
.process-step .signle-p-step:nth-child(2) {
	margin-top: 17%;
	margin-right: 10px;
}
.process-step .signle-p-step:nth-child(4) {
	margin-top: 17%;
	margin-left: 10px;
}
.process-step .signle-p-step:nth-child(3) {
  margin-top: 10.5%;
}
.process-img img {
	height: 66px;
}

.process-step {
	width: 1064px;
	margin: 0 auto;
}

// handle the size of thee box image
.ag-xg-img {
  text-align: center;
  -webkit-transform: translateY(-115px);
  transform: translateY(-115px);
}
.ag-xg-img img {
  max-width: 414px;
}

.process-step-wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 758px;
}
.process-step-wrap::after {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 929px;
  height: 871px;
  background: linear-gradient(135deg, #61c4d1 0%, #ffffff 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#117781', endColorstr='#1f47acb8',GradientType=1 );
	content: "";
	z-index: -1;
	border-radius: 50%;
	-webkit-transform: translate(-50%, -27%);
	transform: translate(-50%, -22%);
}

.btm-btn {
  position: absolute;
  z-index: 20;
  background: #332f47;
  left: 50%;
  bottom: 0;
  width: 275px;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  color: #fff;
  font-size: 22px;
}

.btm-btn span {
  font-size: 22px;
}

.btm-btn:hover {
  text-decoration: none;
  color: #fff;
  cursor: pointer;

  span {
    border-bottom: 1.5px solid #fff;
  }
}

.spacer {
  margin: 8em 0;
}

/* Process area css end    */


/* screen size specific styles */

/* LG Device: >=992px */
@media (min-width: 992px) and (max-width: 1200px) {
	#our-partners-area::after {
		height: 148px;
	} 
	.process-step {
		width: 900px;
		margin: 0 auto;
  }
}



// MD Device: >=768px and <= 991px
@media (min-width: 768px) and (max-width: 991px) {
  .process-step-main h4, .features-content p  {
    font-size: 18px;
  }

  .section-title h1 {
    font-size: 42px;
  }

  .features-content {
    h2 {
      font-size: 30px;
    }
  }
  #our-partners-area::after {
    height: 113px;
  }

  .process-step {
    width: 700px;
    margin: 0 auto;
  }
  .process-img img {
    height: 38px;
  }  
  .process-img {
    width: 100px;
    height: 100px;
    background: #61c2cf;
    text-align: center;
    border-radius: 50%;
    line-height: 100px;
    position: relative;
    margin: 0 auto;
  }
  
  .ag-xg-img img {
    max-width: 326px;
  } 
  .process-step-wrap {
    height: 653px;
  }

  .ag-xg-img {
    text-align: center;
    -webkit-transform: translateY(-115px);
    transform: translateY(-62px);
  } 
  .sale-bar-container {
    font-size: 16px;
  }
  
  .tos-bar-container li {
    font-size: 14px;
  }
   
  .trusted-by {
    li {
      img {
        height: 75px;
      }
    }
  }
  .tests-section {
    .tests-text {
      padding-right: 1em;
    }
    .img-container {
      padding-left: 1em;
    }
  }

    // handles first page section
  .hero-area {
    height: 40rem;
    .img-filter {
      height: 45rem;
    }
    .hero-box {
      width: 75%;
      h1 {
        font-size: 55px;
      }
      p {
        font-size: 22px;
      }
    }
  }
}


@media (max-width: 767px) {
  .homepage-section {
    padding-top: 60px;
		padding-bottom: 56px;
  }
  .section-title {
    h1 {
      font-size: 37px;
      font-weight: 400;
    }
    h2 {
      font-weight: 400;
      font-size: 20px;  
    }
  }

  .process-step-main h4, .features-content p  {
    font-size: 17px;
  }
  .features-content h2 {
    font-size: 28px;
  }

  #our-partners-area::after {
    height: 59px;
  }  

  .sale-bar-container {
    font-size: 14px;
    height: 4em;
    padding: 0.5em 0;
  }
	.tos-bar-container {
		border-radius: 0;
		li {
			font-size: 12px;
			height: 47px;
		}
  }

  .btm-btn {
    display: flex;
      justify-content: center;
      align-items: center;
  }
  .btm-btn span {
    font-size: 17px;
  }  

  .trusted-by {
    li {
      flex: 1 0 33%;
      padding: 0.5em;
      img {
        height: 60px;
      }
    }
  }

	.process-step {
		width: 100%;
		margin: 0 auto;
		padding-bottom: 0.5em;
	}
	.process-section {
		background-position: 44% top;
		padding-top: 140px;
	}
      
	.process-img img {
		height: 38px;
	}  
	.process-img {
		width: 100px;
		height: 100px;
		background: #61c2cf;
		text-align: center;
		border-radius: 50%;
		line-height: 100px;
		position: relative;
		margin: 0 auto;
	}  
	.process-step-main h4 {
		margin-top: 11px;
	}  
	.process-step-wrap {
		height: auto;
		padding-bottom: 45px;
	} 
	.process-step-wrap::after {
		display: none;
	}
	.process-step .signle-p-step:nth-child(1) {
		margin-top: 0;
	}  
	.process-step .signle-p-step:nth-child(2) {
		margin-top: 0;
		margin-right: 0;
	}  
	.process-step .signle-p-step:nth-child(3) {
		margin-top: 0;
	} 
	.process-step .signle-p-step:nth-child(4) {
		margin-top: 0;
		margin-left: 0;
	}  
	.process-step .signle-p-step:nth-child(5) {
		margin-top: 0;
	} 
	.signle-p-step {
		max-width: 50%;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 50%;
	}  

	.ag-xg-img img {
		max-width: 250px;
	}
	.ag-xg-img {
		text-align: center;
		-webkit-transform: translateY(-115px);
		transform: translateY(0px);
		margin: 1em 0;
	}

	.single-injury {
		margin-bottom: 25px;
  }
  
  .tests-section {
    img {
      margin-bottom: 24px;
    }
    h2 {
      text-align: center !important;
      margin-bottom: 16px;
    }
  }

  .hero-area {
    padding: 20px;
    height: 37em;;

    .hero-img {
      background-image: none;
    }

    .hero-box {
      width: 100%;
      margin: 0;
      h1 {
        font-size: 45px;
      }
      p {
        font-size: 18px;
      }
    }
    .img-filter {
      height: 45em;
    }
  }
}
