.car-row {
    width: 100%;
    height: auto;
    padding-bottom: 5em;
    padding-right: 5em;
    padding-left: 5em;
    padding-top: 2em;       
    .article-container {
        padding: 1em;
        .article {
            width: 30em;
            height: 18em;
            display: flex;

            .article-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .text {
                    align-items: flex-start;
                }
                .locale {
                    align-items: flex-end;
                }
                p {
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
    .test-content-container {
        padding: 1em;
        height: 100%;
        .test-content {
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            img {
                height: 135px;
                border-radius: 100%;
                border: 1px solid #fff;
                padding: 0.5em;
                background: #fff;              
            }
            p {
                text-align: center;
                margin-bottom: 0;
                margin-top: 0.5em;
            }    
        } 
    }
}


.paper-container {
    padding: 1em;
    .paper {
        width: 16.5em;
        height: 16.6em;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .paper-header { 
            display: flex;
            align-items: flex-start;
            span {
                font-size: 1.2em;
            }
        }
    }    
}

.media-container {
    height: 90px;
    display: flex;
    justify-content: center;
    .media-logo {
        width: 90%;
        max-height: 100%;
        object-fit: contain;
    }

    #NCIBH_logo_new {
        filter: brightness(0.0);
    }
}

// the following allows for changing the carousel control colors
.carousel-control-next span, .carousel-control-prev span {
    background-image : url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%63c3d0' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-control-prev span {
    transform: rotate(180deg);
}

.carousel .carousel-indicators li {
    background-color: #fff;
    background-color: rgba(128, 128, 128, 1);
}
  
.carousel .carousel-indicators .active {
    background-color: #808080;
}

@media (min-width: 768px) and (max-width: 991px) {
    .car-row {
        width: 100%;
        height: auto;
        padding-bottom: 5em;
        padding-right: 5em;
        padding-left: 5em;
        padding-top: 2em;       

        span {
            font-size: 18px !important;
            color: #212529 !important;
        }
        a {
            font-size: 16px !important;
        }

        .test-content-container .test-content {
            p {
                font-size: 16px;
                color: #212529;    
            }
            img {
                height: 120px;
            }
        }
    }

    .paper, .article-container, .test-container {
        font-size: 18px;
    }
    .paper-container {
        padding: 0;
        .paper {
            width: 15.5em;
            height: 15.5em;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            &.sm {
                width: 12em;
                height: 12em;
            }
        }    
    }

    .article {
        width:  25em;
        height: 18em;
        .article-content {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .media-container {
                margin-bottom: 1em;
            }
        }
    }

    .test-media {
        margin-top: 1em;
        height: 90px;
        width: 90px;
        display:block;
        border: 2px solid #126eff;
        border-radius: 50%;
        padding: 0.5rem;
    }
}



  @media only screen and (max-width: 767px) {
    .car-row {
        height: auto;
        padding-bottom: 5em;
        padding-right: 5em;
        padding-left: 5em;
        padding-top: 2em;       

        span {
            font-size: 16px !important;
            color: #212529 !important;
        }
        a {
            font-size: 14px !important;
        }
        .test-content-container .test-content {
            p {
                font-size: 14px;
                color: #212529;    
            }
            img {
                height: 105px;
            }
        }
    }

    .paper, .article-container, .test-container {
        font-size: 16px;
    }
    .article {
        width: 14.25em;
        height: 25em;
        display: flex;

        .media-container {
            margin-bottom: 1em;
        }
    }

    .test-card {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 12em;
        height: 12em;
    }
    .paper-container {
        padding: 0;
        .paper {
            width: 14.25em;
            height: 14.25em;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            &.sm {
                width: 12em;
                height: 12em;
            }
        }    
    }

    .test-media {
        margin-top: 1em;
        height: 90px;
        width: 90px;
        display:block;
        border: 2px solid #126eff;
        border-radius: 50%;
        padding: 0.5rem;
    }
}

// styling that makes coursel controls not overlap the cards on devices iPhone 5 and older
@media only screen and (max-width: 320px) {
    .carousel-control-next {
        right: -8px;
    }

    .carousel-control-prev {
        left: -8px;
    }
}